// reducers.js
const initialState = {
  TablestartDate: null,
  TableendDate: null,
};

const dateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TABLE_START_DATE':
      return {
        ...state,
        TablestartDate: action.TablestartDate,
      };
    case 'SET_TABLE_END_DATE':
      return {
        ...state,
        TableendDate: action.TableendDate,
      };
    default:
      return state;
  }
};

export default dateReducer;
