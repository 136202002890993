import { combineReducers } from 'redux';
import dateReducer from './dateReducers';
import driverReducer from './driverReducers';
import vehiclesReducer from './VehicleReducers';
import managerReducer from './ManagerReducers';
import TableDates from './TableDateReducers';
import driversZone from './driverZoneReducers';
const rootReducer = combineReducers({
  dates: dateReducer,
  drivers : driverReducer,
  vehicles : vehiclesReducer,
  managers : managerReducer,
  TabelDates : TableDates,
  DriverZone : driversZone
});

export default rootReducer;
