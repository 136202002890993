import React from 'react'
import "./ProfileLayout.css"
import ProfileSidebar from '../../ProfileSidebar/ProfileSidebar'
import { Outlet } from 'react-router-dom'
import Navbar from '../../Navbar/Navbar'
import { NavbarProvider } from '../../Navbar/context/NavbarContext'
export default function ProfileLayout() {
  return (
    <div className='ProfileLayout'>
      <NavbarProvider>
        <Navbar />
      </NavbarProvider>
      <div className='ProfileLayout-child'>
        <div className='ProfileLayout-sidebar'>
          <ProfileSidebar />
        </div>
        <div className='ProfileLayout-child-item'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
