import React, { createContext, useState, useRef } from 'react';

export const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
    const [toggle, setToggle] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [taskId, setTaskId] = useState();
    const [taskId2, setTaskId2] = useState();
    const [status, setStatus] = useState('pending');
    const [status2, setStatus2] = useState('pending');
    const [fileDownload, setFileDownload] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [loader, setLoader] = useState(false);
    const [dataLoader, setDataLoader] = useState(false);
    const [dataLoader2, setDataLoader2] = useState(false);
    const [fileDownload2, setFileDownload2] = useState('');
    const [countNotification, setNotification] = useState();
    const [alertActive, setAlertActive] = useState(false);
    const previousTimestampRef = useRef(null);
    const soundRef = useRef(null);

    const value = {
        toggle, setToggle,
        startDate, setStartDate,
        endDate, setEndDate,
        taskId, setTaskId,
        taskId2, setTaskId2,
        status, setStatus,
        status2, setStatus2,
        fileDownload, setFileDownload,
        isChecked, setIsChecked,
        isChecked2, setIsChecked2,
        loader, setLoader,
        dataLoader, setDataLoader,
        dataLoader2, setDataLoader2,
        fileDownload2, setFileDownload2,
        countNotification, setNotification,
        alertActive, setAlertActive,
        previousTimestampRef,
        soundRef
    };

    return (
        <NavbarContext.Provider value={value}>
            {children}
        </NavbarContext.Provider>
    );
};
