const initalManagerState = {
    managers :[],
};

const managerReducer = (state = initalManagerState, action) => {
      switch (action.type) {
        case 'SET_MANAGERS' : 
          return {
            ...state,
            managers : action.payload,
          };
          default : 
            return state;
      }
};

export default managerReducer;