import React from 'react'
import './Navbar.css';
import '../../Pages/DashboardScreens/RoutePlan/RoutePlan.css'
import { ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import grayIcon from '../../Assets/SOS-Gray.svg'
import { useNavbarHook } from './hooks/NavbarHook';

export default function Navbar() {

    const {
        location,
        navigate_to_dashboard,
        gotoProfile,
        gotoNotification,
        gotoSosNotification,
        cancelReportModal,
        reportDownloadModal,
        handelEndDatechange,
        handelStartDatechange,
        downloadFile,
        handleCheckboxChange,
        handleCheckboxChange2,
        alertActive,
        dataLoader,
        toggle,
        status, 
        status2,
        loader,
        dataLoader2,startDate,
        isChecked,
        isChecked2,
        endDate
    } = useNavbarHook();

    return (
        <div className='navbar-container'>
            <ToastContainer />
            <div className='navbar-dacio-logo-wrapper'>
                {['/Allactivedevices', '/DriverProfile', '/MyDetails', '/CompanyDetails'].includes(location.pathname) ? (
                    <img className='dacio-logo-navbar' src={require('../../Assets/logo.png')} alt="Dacio"
                        style={{ width: 69, height: 18, cursor: 'pointer' }}
                        onClick={() => navigate_to_dashboard()}
                    />
                ) : null}
            </div>

            <div className={`navbar-rightContent ${!['/Allactivedevices', '/DriverProfile', '/MyDetails', '/CompanyDetails'].includes(location.pathname) ? 'navbar-padding' : ''}`}>
                <div className='add-manager-button-container'>
                    <img className='add-manager-button-icon' src={require('../../Assets/File\ type\ icon.png')} />
                    <span className='add-manager-button-text' onClick={() => reportDownloadModal()}>Report Download</span>
                </div>
                {alertActive ?
                    <div className={`Sos-icon`}>
                        <img src={require("../../Assets/SOS.png")} width={40} height={40}
                            onClick={gotoSosNotification}
                        />
                    </div> :
                    <div className={`Sos-icon-gray`}>
                        <img src={grayIcon} width={40} height={40}
                            onClick={gotoSosNotification}
                        />
                    </div>
                }


                <img src={require('../../Assets/bell.png')} alt='Notification' height={20} width={20}
                    style={{ marginRight: 30, cursor: 'pointer', filter: alertActive ? 'invert(41%) sepia(92%) saturate(7452%) hue-rotate(1deg) brightness(91%) contrast(125%)' : "none" }}
                    onClick={() => gotoNotification()}
                />
                <img src={require('../../Assets/user.png')} alt="Logo" height={40} width={40} style={{
                    borderRadius: 200,
                    borderWidth: 0.8,
                    borderColor: 'var(--color-Grey200)',
                    cursor: 'pointer'
                }}
                    onClick={() => gotoProfile()}
                />
            </div>
            <Modal show={toggle} onHide={cancelReportModal}>
                <div className='filtermodal'>
                    <div className='heading-report-tab'>
                        <span className='Addfilter'>Download Report</span>
                        <img src={require("../../Assets/close.png")} width={25}
                            height={25} style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => cancelReportModal()} />
                    </div>
                    <div className='modal-input-container'>
                        <label className='mb-2'>Select Date Range</label>
                        <div className='mydetails-password'>
                            <div className='report-date-conatiner-one'>
                                <div className='Routeplan-date-one'>
                                    <div className='Routeplan-datewrapper-one' id='date-planner'>
                                        <DatePicker
                                            className='Routeplan-date-picker'
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText='Start Date'
                                            selected={startDate}
                                            onChange={handelStartDatechange}
                                        />
                                        <img src={require("../../Assets/calendar-dates.png")} width={15}
                                            height={15} />
                                    </div>
                                </div>

                                <div className='Routeplan-date-one'>
                                    <div className='Routeplan-datewrapper-one' id='date-planner'>
                                        <DatePicker
                                            className='Routeplan-date-picker'
                                            dateFormat='yyyy-MM-dd'
                                            placeholderText='End Date'
                                            selected={endDate}
                                            onChange={handelEndDatechange}
                                        />
                                        <img src={require("../../Assets/calendar-dates.png")} width={15} height={15} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loader ? (
                        <div className='laoder-report'>
                            <div className='small-loader-conatiner'>
                                <span className='small-loader'></span>
                            </div>
                        </div>
                    ) : (!dataLoader && !dataLoader2 && (startDate && endDate) && (!isChecked && !isChecked2)) ? (
                        <span style={{ textAlign: 'center' }}>No Data Available</span>
                    ) : null
                    }
                    {dataLoader ?
                        <div>
                            <label style={{ marginTop: '15px', marginBottom: '15px' }}>Select the file that you want to
                                download.</label>
                            <div className='file-report-data'>
                                <div className='excel-data-tab'>
                                    <div>
                                        <img className='add-report-button-icon'
                                            src={require('../../Assets/file.png')} />
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: '0px' }} className='report-driver-section'>Driver
                                            Statistics
                                            Report</p>
                                        <p style={{ marginBottom: '0px' }}>200 KB</p>
                                    </div>
                                </div>
                                <div>
                                    <input className='report-checkbox' type='checkbox'
                                        checked={isChecked}
                                        onChange={handleCheckboxChange} />
                                </div>
                            </div>
                        </div> : null
                    }

                    {dataLoader2 ?
                        <div>
                            <div className='file-report-data' style={{ marginTop: '15px' }}>
                                <div className='excel-data-tab'>
                                    <div>
                                        <img className='add-report-button-icon'
                                            src={require('../../Assets/file.png')} />
                                    </div>
                                    <div>
                                        <p style={{ marginBottom: '0px' }} className='report-driver-section'>Fleet Utilization
                                            Report</p>
                                        <p style={{ marginBottom: '0px' }}>200 KB</p>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        className='report-checkbox'
                                        type='checkbox'
                                        checked={isChecked2}
                                        onChange={handleCheckboxChange2}
                                    />
                                </div>
                            </div>
                        </div> : ''}


                    <div className='modal-button-container mt-2'>

                        <button className='modal-report-cancel' onClick={() => cancelReportModal()}>Cancel
                        </button>
                        <div>
                            <button
                                className={`theme-button-report confirm-report ${isChecked || isChecked2 ? '' : 'f-btn'}`}
                                disabled={!isChecked && !isChecked2}
                                onClick={() => downloadFile(status, status2)}
                            >
                                <span>Download Now</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

        </div>

    )
}
