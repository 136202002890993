import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import "./Layout.css"
import { NavbarProvider } from '../Navbar/context/NavbarContext'

export default function DashboardLayout() {
  return (
    <div className='layout'>
      <Sidebar />
      <div className='layoutNavbarandContentContainer'>
        <div className='layoutNavbar'>
          <NavbarProvider>
            <Navbar />
          </NavbarProvider>
        </div>
        <div className='layoutContent'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
