import React, { useContext, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const VehicleContext = createContext();

export const VehicleProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isloading, setIsLoading] = useState(false);
  const [vehicleNo, setVehicleNo] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleModal, setVehicleModal] = useState("");
  const [selctedManager, setSelctedManager] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [ownrent, setownrent] = useState("");
  const [insurencedetails, setinsurencedetails] = useState("");
  const [vehicleTypeInfo, setVehicleTypeInfo] = useState("");
  const [managesrs, setManagers] = useState([]);
  const [dataloading, setdataLoading] = useState(false);
  const vehicledata = useSelector((state) => state.vehicles?.vehicles);
  const [Drivers, setDrivers] = useState([]);
  const DriversReduxData = useSelector((state) => state?.drivers?.drivers);
  const userName = sessionStorage.getItem("userName");
  const userId = sessionStorage.getItem("userId");
  const userType = sessionStorage.getItem("userType");
  const [filterModal, setfilterModal] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [error, setError] = useState("");
  const [vehicleModelYear, setVehicleModelYear] = useState([]);

  //filter hooks
  const [filterVehicle, setfilterVehicle] = useState("");
  const [filterManager, setfilterManager] = useState("");
  const [filterInsurance, setfilterInsurance] = useState("");
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <VehicleContext.Provider
      value={{
        loading,
        setLoading,
        status,
        setStatus,
        totalPage,
        setTotalPage,
        totalItems,
        setTotalItems,
        page,
        setPage,
        filterInsurance,
        setfilterInsurance,
        filterManager,
        setfilterManager,
        filterVehicle,
        setfilterVehicle,
        vehicleInfo,
        setVehicleInfo,
        searchQuery,
        setSearchQuery,
        filterModal,
        setfilterModal,
        Drivers,
        setDrivers,
        DriversReduxData,
        vehicledata,
        dataloading,
        setdataLoading,
        managesrs,
        setManagers,
        vehicleTypeInfo,
        setVehicleTypeInfo,
        insurencedetails,
        setinsurencedetails,
        ownrent,
        setownrent,
        selectedDriver,
        setSelectedDriver,
        selctedManager,
        setSelctedManager,
        vehicleModal,
        setVehicleModal,
        vehicleType,
        setVehicleType,
        vehicleId,
        setVehicleId,
        vehicleNo,
        setVehicleNo,
        isloading,
        setIsLoading,
        itemsPerPage,
        currentPage,
        setCurrentPage,
        modal,
        setModal,
        userName,
        userId,
        userType,
        location,
        dispatch,
        limit,
        error,
        setError,
        vehicleModelYear,
        setVehicleModelYear,
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
};

export const useVehicleContext = () => {
  return useContext(VehicleContext);
};
