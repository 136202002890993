export const ErrorCodes = Object.freeze({
    INVALID_INPUT: "DACIO_ERROR_O001",
    UNAUTHORIZED: "DACIO_ERROR_O002",
    NOT_FOUND: "DACIO_ERROR_O003",
    UNKNOWN_ERROR: "DACIO_ERROR_O004",
    MOBILE: "DACIO_ERROR_O005",
    DUPLICATE_EMAIL: "DACIO_ERROR_O006",
    DUPLICATE_PHONE: "DACIO_ERROR_O007",
    DUPLICATE_LICENSE: "DACIO_ERROR_O008",
    DATABASE_ERROR: "DACIO_ERROR_O009",
    INTEGRITY_ERROR: "DACIO_ERROR_O010",
    EXTERNAL_SERVER_ERROR: "DACIO_ERROR_O011"
});

export const LoginError = Object.freeze({
    INCORRECT_CRED: "DACIO_LOGIN_ERROR_001",
    ACCOUNT_LOCKED: "DACIO_LOGIN_ERROR_002",
    ACCOUNT_DISABLED: "DACIO_LOGIN_ERROR_003"
});

export const CompanyError = Object.freeze({
    UNKNOWN_COMPANY: "DACIO_COMPANY_ERROR_001",
    COMPANY_NOT_FOUND: "DACIO_COMPANY_ERROR_002",
    COMPANY_DELETED: "DACIO_COMPANY_ERROR_003",
    COMPANY_FORBIDDEN: "DACIO_COMPANY_ERROR_004",
    COMPANY_EXISTS: "DACIO_COMPANY_ERROR_005",
    COMPANY_RATING: "DACIO_COMPANY_ERROR_006",
    COMPANY_TOP_ALARMS: "DACIO_COMPANY_ERROR_007",
    COMPANY_VEHICLE_STATUS: "DACIO_COMPANY_ERROR_007",
    COMPANY_VEHICLE_EVENTS: "DACIO_COMPANY_ERROR_008"
});

export const UserError = Object.freeze({
    USER_NOT_FOUND: "DACIO_USER_ERROR_001",
    USER_DELETED: "DACIO_USER_ERROR_002",
    USER_INACTIVE: "DACIO_USER_ERROR_003",
    SELF_DELETE_FORBIDDEN: "DACIO_USER_ERROR_004",
    PERMISSION_DENIED: "DACIO_USER_ERROR_005",
    DUPLICATE_LICENSE_NUMBER: "DACIO_USER_ERROR_006"
});

export const VehicleError = Object.freeze({
    VEHICLE_EXISTS: "DACIO_VEHICLE_ERROR_001",
    VEHICLE_NOT_FOUND: "DACIO_VEHICLE_ERROR_002",
    VEHICLE_DELETED: "DACIO_VEHICLE_ERROR_003",
    DRIVER_NOT_FOUND: "DACIO_VEHICLE_ERROR_004",
    DRIVER_ALREADY_ASSOCIATED: "DACIO_VEHICLE_ERROR_005",
    DRIVER_ASSOCIATION_SAME: "DACIO_VEHICLE_ERROR_006",
    DUPLICATE_REG_NUMBER: "DACIO_VEHICLE_ERROR_007",
    DASHCAM_ALREADY_ASSOCIATED: "DACIO_VEHICLE_ERROR_008",
    VEHICLE_ONLINE: "DACIO_VEHICLE_ERROR_009"
});

export const DashCamError = Object.freeze({
    DASHCAM_EXISTS: "DACIO_DASHCAM_ERROR_001",
    DASHCAM_NOT_FOUND: "DACIO_DASHCAM_ERROR_002",
    DASHCAM_DELETED: "DACIO_DASHCAM_ERROR_003",
    DASHCAM_ALREADY_ASSOCIATED: "DACIO_DASHCAM_ERROR_004"
});

export const SimError = Object.freeze({
    SIM_EXISTS: "DACIO_SIM_ERROR_001",
    SIM_NOT_FOUND: "DACIO_SIM_ERROR_002",
    SIM_DELETED: "DACIO_SIM_ERROR_003"
});

export const TaskStatus = Object.freeze({
    TASK_NOT_FOUND: "DACIO_TASK_ERROR_001"
});

export const DacioValueError = Object.freeze({
    TIME_STAMP_ERROR: "DACIO_TIME_STAMP_001"
});

export const AllErrors = Object.fromEntries(
    Object.entries({
      ...ErrorCodes,
      ...LoginError,
      ...CompanyError,
      ...UserError,
      ...VehicleError,
      ...DashCamError,
      ...SimError,
      ...TaskStatus,
      ...DacioValueError
    }).map(([key, value]) => [value, key])
  );