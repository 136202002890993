import React, { useEffect } from 'react';
import { MenuItems } from './SidebarMenu';
import './Sidebar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import { googleLogout } from '@react-oauth/google';

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const userType = sessionStorage.getItem('userType');
  const UserName = sessionStorage.getItem("userName");
  const logOut = () => {
    // googleLogout();
    sessionStorage.clear();
    navigate("/");
  };

  const filteredMenuItems = MenuItems.map(menu => {
    return {
      ...menu,
      Items: menu.Items.filter(item => !(userType === 'manager' && item.title === 'Manager'))
    };
  });

  return (
    <div className='sidebar'>
      <div className='sidebar-wrapper'>
        <div className='sidebar-logo'>
          <img src={require('../../Assets/logo.png')} alt="Dacio" style={{ width: 69, height: 18, marginLeft:15 }} />
        </div>
        {/* sidebar MenuItems */}
        <div className='sidebar-menu'>
          <div className='siderbar-username'>
            <span>Hello</span>
            <span>{UserName}</span>
          </div>
          {filteredMenuItems.map((item, index)=>(
            <div className='menu-title'>
              <span key={index} className='menu-title-text'>{item.menutitle}</span>
              <div className='menu-container'>
                {item.Items.map((subItem, subindex)=>(
                  <Link key={subindex} to={subItem.path} className={`menu-items ${location.pathname == subItem.path ? 'active' : ''}`}>
                    <img src={location.pathname == subItem.path ? subItem.activeIcon : subItem.icon} width={21.5} height={21.5}/>
                    <span className={`menu-items-title-text ${location.pathname == subItem.path ? 'active' : ''}`}>{subItem.title}</span>
                  </Link>
                ))}
              </div>
            </div>
          )
        )}
        </div>
        <div className='logout-container'  onClick={logOut}>
          <img src={require('../../Assets/logout.png')} alt="LogOut" height={24} width={24}/>
          <span className='logout-text'>Logout</span>
        </div>
      </div>
      
    </div>
  )
}
