import { useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import useSound from 'use-sound';
// import mySound from '../../Assets/iphone_amber_alert.mp3';
import { NavbarContext } from '../context/NavbarContext';
import mySound from "../../../Assets/iphone_amber_alert.mp3"
import { fetchSosNotificationsApi } from '../../../Service/apiCalls/sosApi';

export const useNavbarHook = () => {
    const {
        toggle, setToggle,
        startDate, setStartDate,
        endDate, setEndDate,
        taskId, setTaskId,
        taskId2, setTaskId2,
        status, setStatus,
        status2, setStatus2,
        isChecked, setIsChecked,
        isChecked2, setIsChecked2,
        loader, setLoader,
        dataLoader, setDataLoader,
        dataLoader2, setDataLoader2,
        setFileDownload,
        setFileDownload2,
        alertActive, setAlertActive,
    } = useContext(NavbarContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [play, { stop, sound }] = useSound(mySound, { volume: 1 });

    useEffect(() => {
        if (startDate && endDate) {
            const token = sessionStorage.getItem("token");
            if (token) {
                setLoader(true)
                axios.post(`${process.env.REACT_APP_URL}/api/v1/reports/start_driver_metric_report?start_time=${startDate}&end_time=${endDate}`, {}, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `${token}`
                    }
                })
                    .then((response) => {
                        const taskId = response.data.data.task_id;
                        setTaskId(taskId);
                        // pollTaskStatus(taskId);
                    })
                    .catch((error) => {

                        console.error('API error:', error);
                    });
                axios.post(`${process.env.REACT_APP_URL}/api/v1/reports/start_driver_report?start_time=${startDate}&end_time=${endDate}`, {}, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `${token}`
                    }
                })
                    .then((response) => {
                        const taskId = response.data.data.task_id;
                        setTaskId2(taskId);
                        // pollTaskStatus(taskId);
                    })
                    .catch((error) => {

                        console.error('API error:', error);
                    });
            } else {
                console.error('Token not found in sessionStorage.');
            }
        }
    }, [startDate, endDate]);


    // Navigation Handlers
    const navigate_to_dashboard = () => {
        navigate('/Dashboard');
        if (alertActive) {
            stop();
            sessionStorage.removeItem('flag');
        }
    };

    const gotoProfile = () => {
        navigate("/MyDetails");
        if (alertActive) {
            stop();
            sessionStorage.removeItem('flag');
        }
    };

    const gotoNotification = () => {
        navigate("/Notification");
        if (alertActive) {
            stop();
            sessionStorage.removeItem('flag');
        }
    };

    const gotoSosNotification = () => {
        navigate("/SosNotification");
        if (alertActive) {
            stop();
            sessionStorage.removeItem('flag');
        }
    };

    // Modal Handlers
    const cancelReportModal = () => {
        setToggle(false);
        setStartDate(null);
        setEndDate(null);
        setStatus(null);
        setStatus2(null);
        setDataLoader(false);
        setDataLoader2(false);
        setIsChecked(false);
        setIsChecked2(false);
    };

    const reportDownloadModal = () => {
        setToggle(true);
    };

    // Date Handling
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const handelEndDatechange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd 23:00:00');
        setEndDate(formattedDate);
    };

    const handelStartDatechange = (date) => {
        const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        const formattedDate = formatDate(localDate);
        const fixedFormat = `${formattedDate} 00:00:00`;
        setStartDate(fixedFormat);
    };

    // API: Download File
    const downloadFile = (status, status2) => {
        if (isChecked) {
            axios.get(`${process.env.REACT_APP_URL}/api/v1/reports/download/${status}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': sessionStorage.getItem("token"),
                }
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', status);
                    document.body.appendChild(link);
                    link.click();
                    setFileDownload(link);
                    cancelReportModal();
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
        }
        if (isChecked2) {
            axios.get(`${process.env.REACT_APP_URL}/api/v1/reports/download/${status2}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': sessionStorage.getItem("token"),
                }
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', status2);
                    document.body.appendChild(link);
                    link.click();
                    setFileDownload2(link);
                    cancelReportModal();
                })
                .catch((error) => {
                    console.error('API error:', error);
                });
        }
    };

    // API: Get SOS Notifications
    const getSosNotification = async () => {
        try {
          const events = await fetchSosNotificationsApi();
          const newEvents = events.filter(item =>
            item.alert_type_description === "SOS" ||
            item.alert_type_description === 'Vehicle collided' ||
            item.alert_type_description === 'Vehicle tipped over'
          );
    
          if (newEvents && newEvents.length > 0) {
            const firstAlert = newEvents[0];
            const newTimestamp = firstAlert.timestamp;
            const storedTimestamp = localStorage.getItem('previousTimestamp');
    
            if (storedTimestamp === null || storedTimestamp !== newTimestamp) {
              setAlertActive(true);
              localStorage.setItem('previousTimestamp', newTimestamp);
            }
          } else {
            localStorage.removeItem('previousTimestamp');
            setAlertActive(false);
          }
        } catch (error) {
          console.error("Error fetching SOS notifications:", error);
        }
      };

    // Poll for new SOS Notifications
    useEffect(() => {
        getSosNotification();
        const interval = setInterval(() => {
            getSosNotification();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    // Alert Sound Effect Logic
    useEffect(() => {
        let playCount = 0;

        if (alertActive && sound) {
            play();
            playCount++;

            sound.on('end', () => {
                if (playCount === 1) {
                    const secondPlayTimeout = setTimeout(() => {
                        play();
                        playCount++;
                        sound.on('end', () => {
                            setAlertActive(false);
                        });
                    }, 15000);

                    return () => clearTimeout(secondPlayTimeout);
                }
            });

            return () => {
                sound.off('end');
            };
        }
    }, [alertActive, play, sound]);

    // Poll Task Status
    useEffect(() => {
        if (taskId && taskId2) {
            const token = sessionStorage.getItem("token");
            const fetchTaskStatus = () => {
                if (token) {
                    setLoader(true);
                    axios.get(`${process.env.REACT_APP_URL}/api/v1/task_status/${taskId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': sessionStorage.getItem("token"),
                        }
                    })
                        .then((response) => {
                            const status = response.data.data.status;
                            if (status.startsWith('completed')) {
                                const filename = status.split(':')[1];
                                setStatus(filename);
                                setLoader(false);
                                setDataLoader(filename !== 'no_data');
                            } else if (status === 'failed') {
                                setLoader(false);
                            }
                        })
                        .catch((error) => {
                            console.error('API error:', error);
                        });
                    axios.get(`${process.env.REACT_APP_URL}/api/v1/task_status/${taskId2}`, {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': sessionStorage.getItem("token"),
                        }
                    })
                        .then((response) => {
                            const status = response.data.data.status;
                            if (status.startsWith('completed')) {
                                const filename = status.split(':')[1];
                                setStatus2(filename);
                                setLoader(false);
                                setDataLoader2(filename !== 'no_data');
                            } else if (status === 'failed') {
                                setLoader(false);
                            }
                        })
                        .catch((error) => {
                            console.error('API error:', error);
                        });
                }
            };

            const intervalId = setInterval(fetchTaskStatus, 2000);
            return () => clearInterval(intervalId);
        }
    }, [taskId, taskId2]);

    // Handle Checkbox Changes
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleCheckboxChange2 = (event) => {
        setIsChecked2(event.target.checked);
    };

    return {
        location,
        navigate_to_dashboard,
        gotoProfile,
        gotoNotification,
        gotoSosNotification,
        cancelReportModal,
        reportDownloadModal,
        handelEndDatechange,
        handelStartDatechange,
        downloadFile,
        handleCheckboxChange,
        handleCheckboxChange2,
        alertActive,
        dataLoader,
        toggle,
        status, 
        status2,
        loader,
        dataLoader2,startDate,
        isChecked,
        isChecked2,
        endDate
    };
};
