import React, { useContext, createContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ManagerContext = createContext();

export const ManagerProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  // const [managesrs,setManagers]=useState([]);
  const managesrs = useSelector((state) => state.managers?.managers);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataloading, setdataLoading] = useState(false);
  const itemsPerPage = 10;
  const [modal, setModal] = useState(false);
  const [managerName, setManagerName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [managerId, setManagerId] = useState("");
  const [region, setRegion] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [managerprofile, setmanagerprofile] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [filterModal, setfilterModal] = useState(null);
  const [managerInfo, setManagerInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState("");

  const [filterRegion, setfilterRegion] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <ManagerContext.Provider
      value={{
        filterRegion,
        setfilterRegion,
        searchQuery,
        setSearchQuery,
        totalPage,
        setTotalPage,
        totalItems,
        setTotalItems,
        page,
        setPage,
        managerInfo,
        setManagerInfo,
        filterModal,
        setfilterModal,
        isloading,
        setIsLoading,
        managerprofile,
        setmanagerprofile,
        selectedFile,
        setSelectedFile,
        managerId,
        setManagerId,
        region,
        setRegion,
        email,
        setEmail,
        number,
        setNumber,
        managerName,
        setManagerName,
        modal,
        setModal,
        itemsPerPage,
        dataloading,
        setdataLoading,
        currentPage,
        setCurrentPage,
        managesrs,
        dispatch,
        location,
        limit,
      }}
    >
      {children}
    </ManagerContext.Provider>
  );
};

export const useManagerContext = () => {
  return useContext(ManagerContext);
};
