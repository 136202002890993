import { createContext, useContext, useState } from "react";

const DriverContext = createContext();

export const DriverProvider = ({ children }) => {
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedEnd, setSelectedEnd] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalAlertsModal, setTotalAlertsModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [stutusActive, setStatusActive] = useState(true);
  const [driverNumber, setDriverNumber] = useState("");
  const [dmsCount, setDmsCount] = useState("");
  const [adasCount, setAdasCount] = useState("");
  const [vehicle_status, setVehicleStatus] = useState("");
  const [ADASalerts, setADASAlerts] = useState([]);
  const [DMSalerts, setDMSAlerts] = useState([]);
  const [vehicleStatusAlerts, setVehicleStatusAlerts] = useState([]);
  const [driverRating, setDriverRating] = useState("");
  const [totalDistance, setTotalDistance] = useState("");
  const [driverScore, setDriverScore] = useState("");
  const [alertsData, setAlertsData] = useState([]);
  const [overallAlertCount, setOverallAlertCount] = useState("");
  const [TableTripData, setTableTripData] = useState([]);
  const [alertmedia, setAlertmedia] = useState([]);
  const [photosdata, setPhotosdata] = useState([]);
  const [videodata, setVideodata] = useState([]);
  const [modal, setModal] = useState(false);
  const [frontCameraLink, setFrontCameraLink] = useState("");
  const [driverCameraLink, setDriverCameraLink] = useState("");
  const [toggle, setToggle] = useState(true);
  const [TableData, setTableData] = useState([]);
  const [Tabelstart, setTabelstart] = useState(null);
  const [TabelEnd, setTabelEnd] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState("");
  const [mediaAlerts, setMediaAlerts] = useState([]);
  const [selectedmediaAlert, setSelectedMediaAlert] = useState(null);
  const [totalAlarmCount, setTotalAlarmCount] = useState(null);
  const [dataloading, setDataLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alertdescription, setAlertDescription] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [cameralink, setCameralink] = useState(null);
  const [activeCamera, setActiveCamera] = useState("frontCamera");
  const [streamLoader, setStreamLoader] = useState(false);
  const [streamModal, setStreamModal] = useState(false);
  const [criticalCount, setCriticalCount] = useState(0);
  const [nonCriticalCount, setNonCriticalCount] = useState(0);
  const [alarmsData, setAlarmsData] = useState([]);
  const [headarItems, setHeaderItems] = useState([
    {
      headar_name: "Critical",
      count: criticalCount || 0,
    },
    {
      headar_name: "Non Critical",
      count: nonCriticalCount || 0,
    },
  ]);

  const [cameraModel, setCameraModel] = useState("");

  const [mainStream, setMainStream] = useState("");
  const [mainChannelName, setMainChannelName] = useState("");
  const [smallerStream, setSmallerStream] = useState([]);
  const [selectedCameraDevice, setSelectedCameraDevice] = useState("");

  return (
    <DriverContext.Provider
      value={{
        selectedStart,
        setSelectedStart,
        criticalCount,
        setCriticalCount,
        nonCriticalCount,
        setNonCriticalCount,
        selectedEnd,
        setSelectedEnd,
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        setItemsPerPage,
        totalAlertsModal,
        setTotalAlertsModal,
        selectedAlert,
        setSelectedAlert,
        stutusActive,
        setStatusActive,
        driverNumber,
        setDriverNumber,
        dmsCount,
        setDmsCount,
        adasCount,
        setAdasCount,
        vehicle_status,
        setVehicleStatus,
        ADASalerts,
        setADASAlerts,
        DMSalerts,
        setDMSAlerts,
        vehicleStatusAlerts,
        setVehicleStatusAlerts,
        driverRating,
        setDriverRating,
        totalDistance,
        setTotalDistance,
        driverScore,
        setDriverScore,
        alertsData,
        setAlertsData,
        overallAlertCount,
        setOverallAlertCount,
        TableTripData,
        setTableTripData,
        alertmedia,
        setAlertmedia,
        photosdata,
        setPhotosdata,
        videodata,
        setVideodata,
        modal,
        setModal,
        frontCameraLink,
        setFrontCameraLink,
        driverCameraLink,
        setDriverCameraLink,
        toggle,
        setToggle,
        TableData,
        setTableData,
        Tabelstart,
        setTabelstart,
        TabelEnd,
        setTabelEnd,
        selectedHeader,
        setSelectedHeader,
        mediaAlerts,
        setMediaAlerts,
        selectedmediaAlert,
        setSelectedMediaAlert,
        totalAlarmCount,
        setTotalAlarmCount,
        dataloading,
        setDataLoading,
        selectedItem,
        setSelectedItem,
        alertdescription,
        setAlertDescription,
        searchQuery,
        setSearchQuery,
        isStreaming,
        setIsStreaming,
        cameralink,
        setCameralink,
        activeCamera,
        setActiveCamera,
        streamLoader,
        setStreamLoader,
        streamModal,
        setStreamModal,
        alarmsData,
        setAlarmsData,
        headarItems,
        setHeaderItems,
        mainStream,
        setMainStream,
        smallerStream,
        setSmallerStream,
        cameraModel,
        setCameraModel,
        mainChannelName,
        setMainChannelName,
      }}
    >
      {children}
    </DriverContext.Provider>
  );
};

export const useDriverContext = () => useContext(DriverContext);
