const initialDriverState = {
    drivers: [],
};

const driverReducer = (state = initialDriverState, action) => {
    switch (action.type) {
        case 'SET_DRIVERS':
            return {
                ...state,
                drivers: action.payload,
            };
        default:
            return state;
    }
};

export default driverReducer;
