import React from 'react'
import "./ProfileSidebar.css"
import { Link, useLocation } from 'react-router-dom'
export default function ProfileSidebar() {
    const location = useLocation();

    const Items = [
        {
            title : 'My details',
            path  : '/MyDetails'
        },
        {
            title : 'Company Details',
            path  : '/CompanyDetails'
        }
    ]
  return (
    <div className='ProfileSidebar'>
        <div className='Profilesidebar-wrapper'>
        {Items.map((item,index)=>(
            <div className=''>
             <Link key={index} to={item.path} className={`data-item ${location.pathname === item.path ? 'activeProfile' : ''}`} >
               <span>{item.title}</span>
             </Link>
           </div>
        ))}
        </div>
    </div>
  )
}
