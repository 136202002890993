import React from "react"
export const MenuItems = [
    {
        menutitle: '',
        Items: [
            {
                title: 'Dashboard',
                icon: (
                    require('../../Assets/dashboard.png')
                ),
                activeIcon: (
                    require('../../Assets/dashboardActive.png')
                ),
                type: 'sub',
                path:'/Dashboard',
            },
            {
                title: 'Driver',
                icon: (
                    require('../../Assets/driver.png')
                ),
                activeIcon: (
                    require('../../Assets/driverActive.png')
                ),
                type: 'sub',
                path:'/Driver',
            },
            {
                title: 'Vehicles',
                icon: (
                    require('../../Assets/truck.png')                ),
                activeIcon: (
                    require('../../Assets/truckActive.png')
                ),
                type: 'sub',
                path:'/Vehicles',
            },
            {
                title: 'Manager',
                icon: (
                    require('../../Assets/managerIcon.png')
                ),
                activeIcon: (
                    require('../../Assets/manageractive.png')
                ),
                type: 'sub',
                path:'/Manager',
            },

            // {
            //     title: 'Geo- fence',
            //     icon: (
            //         require('../../Assets/inactive-geofence.png')
            //     ),
            //     activeIcon: (
            //         require('../../Assets/active-geofence.png')
            //     ),
            //     type: 'sub',
            //     path:'/GEOFinace',
            // },

            // {
            //     title: 'Routing',
            //     icon: (
            //         require('../../Assets/route-inactive.png')
            //     ),
            //     activeIcon: (
            //         require('../../Assets/route-active.png')
            //     ),
            //     type: 'sub',
            //     path:'/RoutePlan',
            // },
            
        ]
    },
    {
        menutitle: 'Setting',
        Items: [
            {
                title: `FAQ's`,
                icon: (
                    require('../../Assets/faq.png')
                ),
                activeIcon: (
                    require('../../Assets/faqActive.png')
                ),
                type: 'sub',
                path:'/FAQ',
            },
            // {
            //     title: 'Support',
            //     icon: (
            //         require('../../Assets/truck.png')
            //     ),
            //     activeIcon: (
            //         require('../../Assets/truckActive.png')
            //     ),
            //     type: 'sub',
            //     path:'/Support',
            // },
        ]
    }
]