import Api_Service from "../ApiService";
import { Axios_service } from "./axiosService";

export const fetchSosNotificationsApi = async () => {
  try {
    const result = await Api_Service.get(
      "company/events?page=1&size=20&sort_order=desc",
      null,
      false
    );
    return result?.events || [];
  } catch (error) {
    console.error("Error fetching SOS notifications:", error);
    throw error;
  }
};

export const fetchInactiveDetailsApi = async (
  page = 1,
  size = 100,
  collectedData = []
) => {
  try {
    const result = await Api_Service.get(
      `company/events?page=${page}&size=${size}&sort_order=desc`,
      null,
      false
    );
    const notifications = result?.data?.events || [];

    collectedData = [...collectedData, ...notifications];

    if (notifications?.length === size) {
      // Recursively fetch more data if the current batch is full
      return fetchInactiveDetailsApi(page + 1, size, collectedData);
    } else {
      return collectedData;
    }
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};

export const getNotificationInfoApi = async (page, token) => {
  try {
    const params = `?page=${page}&size=10&sort_order=desc`;
    const response = await Axios_service.get(`company/events`, params);
    return response;
  } catch (error) {
    console.error("Error fetching notification info:", error);
    throw error; // Re-throw the error to be handled in the calling function
  }
};
