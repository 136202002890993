import axios, { Axios } from "axios";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const API_URL = `${process.env.REACT_APP_URL}/api/v1/`;
let isRedirecting = false;
const getToken = () => {
    return sessionStorage.getItem("token")
};

const errorMessage = (message) => {
  return new Promise((resolve) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 2000,
      onClose: () => resolve(),
    });
  });
};

export const RefreshTokenApi = async () => {
  try {
    const refreshToken = sessionStorage.getItem("refreshtoken");
    if (!refreshToken) {
      handleRedirect();
    }
    const result = await axios.post(`${API_URL}/auth/refresh`, {
      refresh_token: refreshToken,
    });

    if (result.status === 200) {
      sessionStorage.setItem("userId", result?.data?.data.id);
      sessionStorage.setItem("userName", result?.data?.data.name);
      sessionStorage.setItem("userType", result?.data?.data.type);
      sessionStorage.setItem("token", result?.data?.data.access_token);
      sessionStorage.setItem("refreshtoken", result?.data?.data.refresh_token);
      sessionStorage.setItem("flag", true);
    } else {
      handleRedirect();
    }
  } catch (error) {
    console.error("Token refresh error:", error);
    handleRedirect();
  }
};

const handleRedirect = () => {
  if (!isRedirecting) {
    isRedirecting = true;
    window.location.href = "/";
  }
};


export const Axios_service = {
  get: async (endpoint, params = null, shouldThrowError = true) => {
    try {
      const url = params
        ? `${API_URL}${endpoint}${params}`
        : `${API_URL}${endpoint}`;

      let response = await axios.get(url, {
        headers: {
          Authorization: getToken(),
        },
      });

      if (response.status !== 200) {
        await errorMessage(response.message || "Something went wrong");
      }

      if (response.status === 401 || response.status === 403) {
        await RefreshTokenApi();
        response = await axios.get(url, {
          headers: {
            Authorization: getToken(),
          },
        });
      }
      return response?.data;
    } catch (error) {
      console.error("Fetch error:", error);
      if (shouldThrowError) {
        await errorMessage(error?.message || "Something went wrong");
        throw error;
      } else {
        throw error;
      }
    }
  },
  post: async (endpoint, data, id = null, shouldThrowError = true) => {
    const url = id ? `${API_URL}${endpoint}/${id}` : `${API_URL}${endpoint}`;

    const response = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });

    if (response.status !== 200) {
      await errorMessage(response.message || "Something went wrong");
    }

    if (response.status === 401 || response.status === 403) {
      await RefreshTokenApi();
      response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      });
    }
    return response;
  },
  put: async (endpoint, data, shouldThrowError = true) => {
    const url = `${API_URL}${endpoint}`;

    const response = await axios.put(url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });

    if (response.status !== 200) {
      await errorMessage(response.message || "Something went wrong");
    }

    if (response.status === 401 || response.status === 403) {
      await RefreshTokenApi();
      response = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      });
    }
    return response;
  },
  delete: async (endpoint, params = null, shouldThrowError = true) => {
    const url = params
      ? `${API_URL}${endpoint}${params}`
      : `${API_URL}${endpoint}`;

    const response = await axios.delete(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    });

    if (response.status !== 200) {
      await errorMessage(response.message || "Something went wrong");
    }

    if (response.status === 401 || response.status === 403) {
      await RefreshTokenApi();
      response = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
      });
    }
    return response;
  },
};
