import { createContext, useContext, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const AllDeviceListContext = createContext();

export const AllDeviceListProvider = ({ children }) => {
  // selectors and dispatch
  const dispatch = useDispatch();
  const managesrs = useSelector((state) => state.managers?.managers);
  const Drivers = useSelector((state) => state?.drivers?.drivers);
  const vehicledata = useSelector((state) => state.vehicles?.vehicles);

  // ref
  const platformRef = useRef(null);
  const mapRef = useRef(null);
  const uiRef = useRef(null);
  const mapContainerRef = useRef(null);
  const intervalRef = useRef(null);
  const markerRef = useRef(null);
  const polylineRef = useRef(null);
  const markersRef = useRef([]);

  // states
  const [selectedDevice, setselectedDevice] = useState("Active");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [gpsData, setGpsData] = useState([]);
  const [TimpingGpsdata, setTimpingGpsdata] = useState([]);
  const [setGpsmaually, setsetGpsmaually] = useState([]);
  const [gpsLastData, setGpasLastData] = useState();
  const [alaramGpsData, setalaramGpsData] = useState([]);
  const [vehicleType, setVehicleType] = useState();
  const [frontCameraLink, setFrontCameraLink] = useState("");
  const [driverCameraLink, setDriverCameraLink] = useState("");
  const [vehicleNo, setVehicleNo] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [stutusActive, setStatusActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [dataloading, setdataLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [ActiveVehicles, setActiveVehicles] = useState([]);
  const [InactiveVehicles, setInactiveVehicles] = useState([]);
  const [gpsDataArray, setGpsDataArray] = useState([]);
  const [alarmDataArray, setAlarmDataArray] = useState([]);
  const [coordinatedata, setcoordinatedata] = useState();
  const [alarmMarkers, setAlarmMarkers] = useState([]);
  const [endlocation, setEndlocation] = useState(null);
  const [startlocation, setStartlocation] = useState(null);
  const [Avgspeed, setAvgspeed] = useState(null);
  const [gpsSignal, setgpsSignal] = useState(null);
  const [lastCoordinate, setLastCoordinate] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [marker, setMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [cameralink, setcameralink] = useState(null);
  const [activeCamera, setactiveCamera] = useState("frontCamera");
  const [streamLoader, setstreamLoader] = useState(false);
  const [streamModal, setstreamModal] = useState(false);
  const [front, setFront] = useState("");
  const [coordinates, setcoordinates] = useState([]);
  const [mapData, setMapData] = useState(null);
  const [activeVehicleCount, setActiveVehicleCount] = useState(-1);
  const [inActiveVehicleCount, setInActiveVehicleCount] = useState(-1);
  const [vehicleRegNumber, setVehicleRegNumber] = useState("");
  const [cameraCount, setCameraCount] = useState(0);
  const [cameraModel, setCameraModel] = useState("");
  const [vehId, setVehId] = useState(null);

  return (
    <AllDeviceListContext.Provider
      value={{
        cameraCount,
        setCameraCount,
        cameraModel,
        setCameraModel,
        mapData,
        setMapData,
        coordinates,
        selectedLocation,
        setSelectedLocation,
        setcoordinates,
        front,
        setFront,
        dispatch,
        managesrs,
        Drivers,
        vehicledata,
        selectedDevice,
        setselectedDevice,
        selectedVehicle,
        setSelectedVehicle,
        gpsData,
        setGpsData,
        TimpingGpsdata,
        setTimpingGpsdata,
        setGpsmaually,
        gpsLastData,
        setGpasLastData,
        alaramGpsData,
        setalaramGpsData,
        vehicleType,
        setVehicleType,
        frontCameraLink,
        setFrontCameraLink,
        driverCameraLink,
        setDriverCameraLink,
        vehicleNo,
        setVehicleNo,
        toggle,
        setToggle,
        stutusActive,
        setStatusActive,
        searchTerm,
        setSearchTerm,
        dataloading,
        setdataLoading,
        page,
        setPage,
        hasMoreData,
        setHasMoreData,
        ActiveVehicles,
        setActiveVehicles,
        InactiveVehicles,
        setInactiveVehicles,
        gpsDataArray,
        setGpsDataArray,
        alarmDataArray,
        setAlarmDataArray,
        platformRef,
        mapRef,
        uiRef,
        mapContainerRef,
        coordinatedata,
        setcoordinatedata,
        alarmMarkers,
        setAlarmMarkers,
        endlocation,
        setEndlocation,
        startlocation,
        setStartlocation,
        Avgspeed,
        setAvgspeed,
        gpsSignal,
        setgpsSignal,
        intervalRef,
        lastCoordinate,
        setLastCoordinate,
        polyline,
        setPolyline,
        marker,
        setMarker,
        map,
        setMap,
        search,
        setSearch,
        modal,
        setModal,
        isStreaming,
        setIsStreaming,
        cameralink,
        setcameralink,
        activeCamera,
        setactiveCamera,
        streamLoader,
        setstreamLoader,
        streamModal,
        setstreamModal,
        markerRef,
        polylineRef,
        setsetGpsmaually,
        vehicleRegNumber,
        setVehicleRegNumber,
        activeVehicleCount,
        setActiveVehicleCount,
        inActiveVehicleCount,
        setInActiveVehicleCount,
        vehId, setVehId
      }}
    >
      {children}
    </AllDeviceListContext.Provider>
  );
};

export const useAllDeviceListContext = () => {
  return useContext(AllDeviceListContext);
};
