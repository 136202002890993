// store.js
import { createStore, applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'; // Importing thunk as the default export
import rootReducer from '../Redux/index'; 
const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;
