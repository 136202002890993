const initalVehcileState = {
    vehicles : [],
};

const vehiclesReducer =(state = initalVehcileState, action)=> {
      switch (action.type) {
        case 'SET_VEHICLE' : 
          return {
             ...state,
             vehicles : action.payload,
          };
          default : 
           return state;
      }
};

export default vehiclesReducer;