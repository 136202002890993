import React, {
  useContext,
  createContext,
  Children,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const DashboardContext = createContext();

export const DashBoardProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.dates);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datePickerRef = useRef(null);

  const [activeCount, setActiveCount] = useState("");
  const [overallCount, setOverallCount] = useState("");
  const [slectedItem, setSelectedItem] = useState("Red");
  const [redZoneData, setRedZoneData] = useState([]);
  const [orangeZoneData, setOrangeZoneData] = useState([]);
  const [greenZoneData, setGreenZoneData] = useState([]);

  const [avgScore, setAvgScore] = useState("");
  const [alertTypesData, setAlertTypesData] = useState([]);
  const [graphBarData, setGraphBarData] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [selectedDriverName, setSelectedDriverName] = useState("");
  const [selectednumber, setselectednumber] = useState("");
  const [uniuqeAlertdata, setuniuqeAlertdata] = useState([]);
  const [intialStartDate, setintialStartDate] = useState(null);
  const [intialEndDate, setintialEndDate] = useState(null);

  const [driverData, setDriverData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [Alldriverdata, setAlldriverdata] = useState([]);

  const [page, setPage] = useState(2);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: "donut",
      },
      labels: [],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false, // Hide all labels
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true, // Show legend
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 194,
              height: 194,
            },
            legend: {
              position: "center",
            },
          },
        },
      ],
    },
  });

  return (
    <DashboardContext.Provider
      value={{
        chartData,
        setChartData,
        page,
        setPage,
        Alldriverdata,
        setAlldriverdata,
        vehicleData,
        setVehicleData,
        driverData,
        setDriverData,
        uniuqeAlertdata,
        setuniuqeAlertdata,
        selectednumber,
        setselectednumber,
        selectedDriverName,
        setSelectedDriverName,
        selectedDriver,
        setSelectedDriver,
        graphBarData,
        setGraphBarData,
        alertTypesData,
        setAlertTypesData,
        avgScore,
        setAvgScore,
        greenZoneData,
        setGreenZoneData,
        orangeZoneData,
        setOrangeZoneData,
        redZoneData,
        setRedZoneData,
        slectedItem,
        setSelectedItem,
        overallCount,
        setOverallCount,
        activeCount,
        setActiveCount,
        intialEndDate,
        setintialEndDate,
        intialStartDate,
        setintialStartDate,
        showDatePicker,
        setShowDatePicker,
        datePickerRef,
        navigate,
        dispatch,
        startDate,
        endDate,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};
