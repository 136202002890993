import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import ProtectedLayout from "./Shade/Layouts/ProtectedLayout/ProtectedLayout";
import Loader from "./Components/Loader/Loader";
import ProfileLayout from "./Shade/Layouts/ProfileLayout/ProfileLayout";
import Navbar from "./Shade/Navbar/Navbar";
import { DriverProvider } from "./Pages/DashboardScreens/Driver/context/useDriverProfileContext";
import { LoginProvider } from "./Pages/OnBoardingScreens/Loginscreen/useLoginContext";
import { VehicleProvider } from "./Pages/DashboardScreens/Vehicles/useVehicleContext";
import { ManagerProvider } from "./Pages/DashboardScreens/Manager/useManagerContext";
import { AllDeviceListProvider } from "./Pages/DashboardScreens/AllactiveDeviceList/allActiveDeviceContext";
import { DashBoardProvider } from "./Pages/DashboardScreens/Dashboard/useDashboardContext";
import { NavbarProvider } from "./Shade/Navbar/context/NavbarContext";

const WelcomeScreen = React.lazy(() =>
  import("./Pages/OnBoardingScreens/WelcomeScreen/WelcomeScreen")
);
const Login = React.lazy(() =>
  import("./Pages/OnBoardingScreens/Loginscreen/Login")
);
const Dashboard = React.lazy(() =>
  import("./Pages/DashboardScreens/Dashboard/Dashboard")
);
const Driver = React.lazy(() =>
  import("./Pages/DashboardScreens/Driver/Driver")
);
const Vehicles = React.lazy(() =>
  import("./Pages/DashboardScreens/Vehicles/Vehicles")
);
const Manager = React.lazy(() =>
  import("./Pages/DashboardScreens/Manager/Manager")
);
const FAQ = React.lazy(() => import("./Pages/DashboardScreens/FAQ/FAQ"));
const Support = React.lazy(() =>
  import("./Pages/DashboardScreens/Support/Support")
);
const Notification = React.lazy(() =>
  import("./Pages/DashboardScreens/NotificationScreen/Notification")
);
const AllactiveDeviceList = React.lazy(() =>
  import("./Pages/DashboardScreens/AllactiveDeviceList/AllactiveDeviceList")
);
//Driver Profile
const DriverProfile = React.lazy(() =>
  import("./Pages/DashboardScreens/Driver/DriverProfile/DriverProfile")
);
const SosNotification = React.lazy(() =>
  import("./Pages/DashboardScreens/SosNotification/SosNotification")
);

//GEO-Finace
const GEOFinace = React.lazy(() =>
  import("./Pages/DashboardScreens/GEO-Finacing/GEOFinace")
);
const AddGEOFinance = React.lazy(() =>
  import("./Pages/DashboardScreens/GEO-Finacing/AddGEOFinance")
);

// Routing plan
const RoutePlan = React.lazy(() =>
  import("./Pages/DashboardScreens/RoutePlan/RoutePlan")
);

// My Profile
const MyDetails = React.lazy(() =>
  import("./Pages/DashboardScreens/MyProfile/MyDetails/MyDetails")
);
const CompanyDeatils = React.lazy(() =>
  import("./Pages/DashboardScreens/MyProfile/CompanyDetails/CompanyDetails")
);
// Error404
const Error404 = React.lazy(() =>
  import("./Shade/Layouts/ErrorLayout/Error404")
);
const ProtectedRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      return navigate("/", { replace: true });
    }
  }, [navigate]);

  const token = sessionStorage.getItem("token");
  if (!token) {
    return null;
  }
  return <Outlet />;
};

function App() {
  return (
    <div className="App">
      <React.Fragment>
        <HashRouter>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              {/* <Route path='/' index element={<WelcomeScreen/>}/> */}
              <Route
                path="/"
                index
                element={
                  <LoginProvider>
                    <Login />
                  </LoginProvider>
                }
              />

              <Route element={<ProtectedRoutes />}>
                <Route element={<ProtectedLayout />}>
                  <Route
                    path="/Dashboard"
                    element={
                      <DashBoardProvider>
                        <Dashboard />
                      </DashBoardProvider>
                    }
                  />
                  <Route path="/Driver" element={<Driver />} />
                  <Route
                    path="/Vehicles"
                    element={
                      <VehicleProvider>
                        <Vehicles />
                      </VehicleProvider>
                    }
                  />
                  <Route
                    path="/Manager"
                    element={
                      <ManagerProvider>
                        <Manager />
                      </ManagerProvider>
                    }
                  />
                  <Route path="/GEOFinace" element={<GEOFinace />} />
                  <Route path="/AddGEOFinance" element={<AddGEOFinance />} />
                  <Route path="/RoutePlan" element={<RoutePlan />} />
                  <Route path="/FAQ" element={<FAQ />} />
                  <Route path="/Support" element={<Support />} />
                  <Route path="/Notification" element={<Notification />} />
                  <Route
                    path="/SosNotification"
                    element={<SosNotification />}
                  />
                </Route>
                <Route element={<ProfileLayout />}>
                  <Route path="/MyDetails" element={<MyDetails />} />
                  <Route path="/CompanyDetails" element={<CompanyDeatils />} />
                </Route>
                <Route
                  path="/DriverProfile"
                  element={
                    <DriverProvider>
                      <NavbarProvider>
                        <Navbar />
                      </NavbarProvider>
                      <DriverProfile />
                    </DriverProvider>
                  }
                />
                <Route
                  path="/Allactivedevices"
                  element={
                    <>
                      <AllDeviceListProvider>
                        <NavbarProvider>
                          <Navbar />
                        </NavbarProvider>
                        <AllactiveDeviceList />
                      </AllDeviceListProvider>
                    </>
                  }
                />
              </Route>

              <Route path="/Error404" element={<Error404 />} />
            </Routes>
          </React.Suspense>
        </HashRouter>
      </React.Fragment>
    </div>
  );
}

export default App;
