import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {

  const [isChecked, setIsChecked] = useState(false);
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [passwordModal, setPasswordModal] = useState(false);
  const [pushToekn, setPushToken] = useState();
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [loginNumber, setLoginNumber] = useState("");
  
  const [passwordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  const [errors, setErrors] = useState({
    current: "",
    new: "",
    confirm: "",
  });

  return (
    <LoginContext.Provider
      value={{
        countryCode,
        setCountryCode,
        isChecked,
        setIsChecked,
        number,
        setNumber,
        password,
        setPassword,
        active,
        setActive,
        isloading,
        setIsLoading,
        activeSlide,
        setActiveSlide,
        passwordModal,
        setPasswordModal,
        pushToekn,
        setPushToken,
        passwordVisible,
        setPasswordVisible,
        passwords,
        setPasswords,
        errors,
        setErrors,
        error,
        setError,
        loginNumber,
        setLoginNumber,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
