const initialDriverState = {
    driversZone: [],
};

const driversZone = (state = initialDriverState, action) => {
    switch (action.type) {
        case 'SET_DRIVERSZONE':
            return {
                ...state,
                driversZone: action.payload,
            };
        default:
            return state;
    }
};

export default driversZone;